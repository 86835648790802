import { render, staticRenderFns } from "./NewFQCAdminUpload.vue?vue&type=template&id=146207ae&scoped=true"
import script from "./NewFQCAdminUpload.vue?vue&type=script&lang=js"
export * from "./NewFQCAdminUpload.vue?vue&type=script&lang=js"
import style0 from "./NewFQCAdminUpload.vue?vue&type=style&index=0&id=146207ae&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146207ae",
  null
  
)

export default component.exports